export default defineNuxtRouteMiddleware(() => {
  const { status: authStatus, data: user } = useAuth()
  const nuxt = useNuxtApp()
  // This should never happen, but just in case.
  // The user should always be authenticated (checked by the auth middleware)
  if (!user.value || authStatus.value !== 'authenticated') return navigateTo(nuxt.$localePath('/'))

  // If the user is not a superadmin, redirect to the home page
  if (!isHrAdmin(user.value)) {
    if (isSuperAdmin(user.value)) return navigateTo(nuxt.$localePath('admin'))
    return navigateTo(nuxt.$localePath('/onboard/company'))
  }
})
